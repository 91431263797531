import * as React from "react"
import { navigate } from "@reach/router";
import { navigate as navigateGatsby } from "gatsby";
// import SEO from "../../../components/seo";
import _ from "lodash";
import OffPlanTemplate from "../../templates/off-plan-template";
import SEO from "../../components/seo";

class OffPlan extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            // query: "",
            // min: "",
            // max: "",
            // bedrooms: "",
            // building: "",
            searchType: "sales",
            department: "new-developments",
            searchBaseUrl: "/map-of-off-plan-developments",
            // popular: "",
            // page: ""
        }
    }
    // changeStateAndUrl = (searchState) => {
    //     console.log(searchState, "SearchState")
    //     let urls = [];
    //
    //     if(typeof searchState.query !== "undefined") {
    //         if(searchState.query !== "") {
    //             urls.push(`in-${searchState.query}`)
    //             this.setState({ query: searchState.query })
    //         } else {
    //             this.setState({ query: searchState.query })
    //         }
    //     } else {
    //         urls.push("in-dubai")
    //     }
    //
    //     // create building URL
    //     if(typeof searchState.menu !== "undefined") {
    //         if(searchState.menu.building) {
    //             urls.push(`type-${searchState.menu.building}`)
    //             this.setState({ building: searchState.menu.building })
    //         } else {
    //             this.setState({ building: ""})
    //         }
    //         if(searchState.menu.accomadation_summary) {
    //             this.setState({ popular: searchState.menu.accomadation_summary })
    //         }
    //     } else if(this.state.building !== "") {
    //         urls.push(`type-${this.state.building}`)
    //     }
    //
    //     // create bedroom URL
    //     if(searchState.range && searchState.range.bedroom) {
    //         let bedroom = searchState.range.bedroom.min;
    //         if(bedroom) {
    //             urls.push(`${bedroom}-and-more-bedrooms`)
    //             this.setState({ bedrooms: bedroom })
    //         } else {
    //             this.setState({ bedrooms: "" })
    //         }
    //     }
    //
    //     // create Price min and Max URL
    //     if(typeof searchState.range !== "undefined" && typeof searchState.range.price !== "undefined") {
    //         let price = searchState.range.price;
    //         let min = price.min;
    //         let max = price.max;
    //         if(min && max) {
    //             urls.push(`between-${min}-and-${max}`)
    //             this.setState({ min, max })
    //         } else {
    //             if(min) {
    //                 urls.push(`over-${min}`)
    //                 this.setState({ min })
    //             } else if(this.state.min !== "") {
    //                 this.setState({ min: ""})
    //             }
    //             if(max) {
    //                 urls.push(`under-${max}`)
    //                 this.setState({ max })
    //             } else if(this.state.max !== ""){
    //                 this.setState({ max: ""})
    //             }
    //         }
    //     } else {
    //         let min = this.state.min;
    //         let max = this.state.max;
    //         if(min !== "" && max !== "") {
    //             urls.push(`between-${min}-and-${max}`)
    //             this.setState({ min, max })
    //         } else {
    //             if(min) {
    //                 urls.push(`over-${min}`)
    //                 this.setState({ min })
    //             } else {
    //                 this.setState({ min: ""})
    //             }
    //             if(max) {
    //                 urls.push(`under-${max}`)
    //                 this.setState({ max })
    //             } else {
    //                 this.setState({ max: ""})
    //             }
    //         }
    //     }
    //
    //     if(typeof searchState.page !== "undefined") {
    //         if(searchState.page !== "" && searchState.page != 1) {
    //             urls.push(`page-${searchState.page}`)
    //             this.setState({ page: searchState.page })
    //         }
    //     }
    //
    //     let url = urls.join('/');
    //     let newRoute = '/properties/for-sale/'+url;
    //     navigate(newRoute)
    // }
    componentDidMount() {
        if(window)
            window.scroll({
                top: -100,
                behavior: 'smooth'
            });
        // const { params } = this.props;
        // const searchParams = params['*'].split('/');
        // searchParams.forEach(param => {
        //     if(param.includes("in-") && !param.includes("in-dubai")) {
        //     // if(param.includes("in-")) {
        //         this.setState({ query: param.substring(3)})
        //     }
        //     if(param.includes("under-")) {
        //         this.setState({ max: param.substring(6)})
        //     }
        //     if(param.includes("over-")) {
        //         this.setState({ min: param.substring(5)})
        //     }
        //     if(param.includes("-and-more-bedrooms")) {
        //         this.setState({ bedrooms: param.split("-")[0]})
        //     }
        //     if(param.includes("type-")) {
        //         this.setState({ building: param.split("-")[1]})
        //     }
        //     if(param.includes("between-")) {
        //         this.setState({
        //             min: param.split("-")[1],
        //             max: param.split("-")[3]
        //         })
        //     }
        // });
    }
    changeToMapUrl = () => {
        let pathname = this.props.location.pathname.replace("/off-plan", "/map-of-off-plan");
        let url = ""
        if(pathname.includes("page-")) {
            let urls = pathname.split("/");
            urls.pop();
            url = urls.join("/");
        } else {
            url = pathname;
        }
        navigateGatsby(url)
    }
    changeToListUrl = () => {
        let url = this.props.location.pathname.replace("/map-of-off-plan", "/off-plan");
        navigateGatsby(url)
    }
    render() {
        // var search_area = "Dubai";
        // var price_range = "", property_type = "", bedrooms = "";
        // if(this.props.params["*"]) {
        //     search_area = this.props.params["*"].split("/").filter(c => c.includes("in")).length > 0 ? "in "+ this.props.params["*"].split("/").filter(c => c.includes("in"))[0].split("in-")[1].split("-").map(c => _.upperFirst(c)).join(" ") : "in Dubai";
        //     price_range = this.props.params["*"].split("/").filter(c => c.includes("over-") || c.includes("below-") || c.includes("between-")).length > 0 ? this.props.params["*"].split("/").filter(c => c.includes("over-") || c.includes("below-") || c.includes("between-"))[0].split("-").join(" ") : "";
        //     property_type = this.props.params["*"].split("/").filter(c => c.includes("type-")).length > 0 ? this.props.params["*"].split("/").filter(c => c.includes("type-"))[0].split("type-").map(c => _.upperFirst(c))[1] : "";
        //     bedrooms = this.props.params["*"].split("/").filter(c => c.includes("-and-more-bedrooms")).length > 0 ? this.props.params["*"].split("/").filter(c => c.includes("-and-more-bedrooms"))[0].split("-and-more-bedrooms")[0] : "";
        // }
        return (
            <>
                {/*<SEO
                    title={
                        (price_range !== "" && property_type !== "" && bedrooms !== "") ?
                        `${bedrooms} Bedroom ${property_type} for Sale ${search_area} ${price_range}` :
                        (property_type !== "" && bedrooms !== "") ?
                        `${bedrooms} Bedroom ${property_type} for Sale in ${search_area}` :
                        (price_range !== "" && bedrooms !== "") ?
                        `${bedrooms} Bedroom for Sale in ${search_area} ${price_range}` :
                        (price_range !== "" && property_type !== "") ?
                        `${property_type} for Sale in ${search_area} ${price_range}` :
                        (bedrooms !== "") ?
                        `${bedrooms} Bedroom for Sale in ${search_area}` :
                        (property_type !== "") ?
                        `${property_type} for Sale in ${search_area}` :
                        (price_range !== "") ?
                        `Property for Sale ${search_area} ${price_range}` :
                        `Property for Sale ${search_area}`
                    }
                    description={
                        (price_range !== "" && property_type !== "" && bedrooms !== "") ?
                        `Check out ${bedrooms} bedroom ${property_type} for sale in Dubai ${price_range} with Espace, our experts help you find the perfect ${property_type} in Dubai.`:
                        (property_type !== "" && bedrooms !== "") ?
                        `Discover the best ${bedrooms} bedroom ${property_type} for sale in Dubai, Contact Espace leading estate and letting agents in Dubai.` :
                        (price_range !== "" && bedrooms !== "") ?
                        `Find ${bedrooms} bedroom property for sale in Dubai with Espace, You can book a free property valuation with us for accurate property valuations.`:
                        (price_range !== "" && property_type !== "") ?
                        `Here is the latest ${property_type} for sale in Dubai for you. Contact Espace, the leading estate and letting agents in the Dubai today.`:
                        (bedrooms !== "") ?
                        `Browse ${bedrooms} bedroom property for sale in Dubai. Espace offers a wide range of Property for sale in Dubai.`:
                        (property_type !== "") ?
                        `Looking for ${property_type} for sale in Dubai? check here for the finest collection of Buckinghamshire property. property experts. Book an appointment with one of our specialists today.`:
                        (price_range !== "") ?
                        `Discover the wide range of Property for Sale ${search_area} ${price_range} with Espace. Book an appointment with one of our specialists today to find the perfect property for sale anywhere in Dubai.`:
                        `Find the best property for Sale ${search_area}. Contact our property experts and get help to find Property for Sale in Dubai`
                    }
                />*/}
                <OffPlanTemplate
                    {...this.state}
                    // changeStateAndUrl={this.changeStateAndUrl}
                    changeToMapUrl={this.changeToMapUrl}
                    changeToListUrl={this.changeToListUrl}
                    location={this.props.location}
                    mapView={this.props.location.pathname.includes("/map-of-off-plan")}
                    layout={``}
                />
            </>
        )
    }
}

export default OffPlan;
